<template>
  <el-dialog
    :title="modalType === 'edit' ? '编辑代理商' : '新增代理商'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
    <el-form-item label="代理商名称：" prop="operationName">
        <el-input
          v-model="modalData.operationName"
          placeholder="请输入代理商名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>
      
      <el-form-item label="代理商类型" prop="type">
        <el-select
          v-model="modalData.type"
          placeholder="请选择代理商类型"
          :maxLength="50"
        >
          <el-option
            v-for="(item, i) in this.const.AGENT_LEVEL"
            :value="item.value"
            :label="item.name"
            :key="i"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="代理商上级：" prop="pid">
        <el-select
          v-model="modalData.pid"
          placeholder="请选择代理商上级"
          :maxLength="50"
        >
          <el-option label="无" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="代理地区：" prop="area">
        <el-cascader
          v-model="modalData.area"
          placeholder="请选择代理地区"
          :options="areasList"
          @change="handleChange"
          :props="{ value: 'name', label: 'name', checkStrictly: true }"
          filterable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="分红方式：" prop="rate">
        <el-input
          v-model="modalData.rate"
          placeholder="请输入分红方式"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="负责人姓名：" prop="realName">
        <el-input
          v-model="modalData.realName"
          placeholder="负责人姓名"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="负责人性别：" prop="sex">
        <el-radio-group v-model="modalData.sex">
          <el-radio label="男" value="1"></el-radio>
          <el-radio label="女" value="2"></el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="负责人手机：" prop="mobile">
        <el-input
          v-model="modalData.mobile"
          placeholder="请输入负责人手机"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="负责人邮箱：" prop="email">
        <el-input
          v-model="modalData.email"
          placeholder="请输入负责人邮箱"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
export default {
  mixins: [modalMixin],
  props: ["modalData", "modalType"],
  data() {
    return {
      areasList: areas,
      formName: "form",
      ruleValidate: {
        type: [
          {
            required: true,
            message: "请选择代理商类型",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入联系人邮箱",
            trigger: "change",
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "change",
          },
        ],

        mobile: [
          {
            required: true,
            message: "请输入联系人电话",
            trigger: "change",
          },
        ],

        sex: [
          {
            required: true,
            message: "请选择联系人性别",
            trigger: "change",
          },
        ],

        rate: [
          {
            required: true,
            message: "请输入分红比率",
            trigger: "change",
          },
        ],

        area: [
          {
            required: true,
            message: "请选择代理地区",
            trigger: "change",
          },
        ],
        operationName: [
          {
            required: true,
            message: "请输入代理名称",
            trigger: "change",
          },
        ],

        pid: [
          {
            required: true,
            message: "请选择代理商上级",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.validateForm().then((res) => {
        if (!this.validatenull(this.modalData.area)) {
          this.modalData.provinceId = this.modalData.area[0];
          this.modalData.cityId = this.modalData.area[1];
          this.modalData.areaid = this.modalData.area[2];
        }

        this.$emit("submit", this.modalData);
      });
    },
  },
};
</script>
